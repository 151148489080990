<template>
  <div class="header-page">
    <headerBar :title="i18n.t('header.title')"  :showArrow="true" @back="back"></headerBar>

    <div  class="header-top flex-column-center">
      <div class="header" :class="`header-${header}`"></div>
      <div class="btn" @click="submit">{{i18n.t('header.btn')}}</div>
    </div>

    <div class="header-list flex-wrap">
      <div class="header-box flex-center" v-for="i in 90" :key="i" @click="changeHeader(i)">
        <div class="header"  :class="`header-${i}`"></div>
      </div>
    </div>
  </div>
</template>
<script>
import { Button } from 'vant'
import headerBar from '@/components/header'
import {  editUser } from '@/api/user'
export default {
  components: {
    headerBar,
    Button
  },
  data() {
    return {
      header: 91
    }
  },
  mounted() {

  },
  methods: {
    back() {
      this.$router.go(-1)
    },
    changeHeader(i) {
      this.header = i
      
    },
    submit() {
      editUser({
        avatar: this.header + ''
      }).then(res => {
        if (res.code == 0) {
          this.$store.state.avatar = this.header
          this.$toast(this.i18n.t('common.success'))
        } else {
          this.$toast(res.msg)
        }
      })
    }
  }
}
</script>